import { Component, Vue } from 'vue-facing-decorator'
import { FormGroup, FormSection, TextField, TextareaField, SelectField, FormValidation } from '@/components/Forms'
import ExamView from '@/components/Exams/ExamView.vue'
import Loading from '@/components/Loading.vue'
import QuestionTemplatesList from '@/components/Jobs/QuestionTemplatesList.vue'
import { List, type IListOptions } from '@/components/Lists'
import ProgressModal from '@/components/ProgressModal.vue'
import * as R from 'ramda'
import type { CMS, Study } from '@pocketprep/types'
import { objPointer } from '@/store/ParseUtils'
import { activitiesModule } from '@/store/activities/module'
import type { TQuestionType } from '@/store/types'
import type { TEnhancedQuestion } from '@/store/questions/types'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import examsModule from '@/store/exams/module'
import examDraftsModule from '@/store/examDrafts/module'
import type { IQuestionDraft } from '@/store/questionDrafts/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import questionsModule from '@/store/questions/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'
import { bloomTaxonomyLevels, stripCKEditorTags } from '@/utils'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'
import * as Sentry from '@sentry/browser'

interface IVersionInfo {
    type: 'Major' | 'Minor' | 'Patch'
    description: string
}

interface IMappedQuestion {
    objectId: string
    serial: string
    knowledgeAreaDraft: string
    prompt: string
    type: TQuestionType
    draftType: 'New' | 'Updated' | 'Existing'
    isArchived: 'Yes' | 'No'
    isFree: 'Yes' | 'No'
    appName: string
    subCategory: string
    answers: string[]
    explanation: string
    passage: string
    references: string[]
    distractors: string[]
    dateAdded?: string
    images?: { 
        explanation?: {
            url: string
            altText: string
            longAltText?: string
        }
        passage?: {
            url: string
            altText: string
            longAltText?: string
        }
    }
    willResetMetrics: 'Yes' | 'No'
    isMockQuestion: 'Yes' | 'No'
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    subtopic: string
}

@Component({
    components: {
        ExamView,
        FormGroup,
        FormSection,
        TextField,
        QuestionTemplatesList,
        List,
        SelectField,
        Loading,
        ProgressModal,
        FormValidation,
        TextareaField,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class ExamDraftExport extends Vue {
    s3BucketName = ''
    versionInfo: IVersionInfo = {
        type: 'Patch',
        description: '',
    }
    examDraft: CMS.Class.ExamDraftJSON | null = null
    activeQuestions: CMS.Class.QuestionDraftJSON[] = []
    updatedQuestions: CMS.Class.QuestionDraftJSON[] = []
    resetQuestions: TEnhancedQuestion[] = []
    newQuestions: CMS.Class.QuestionDraftJSON[] = []
    existingQuestions: CMS.Class.QuestionDraftPayload[] = []
    originalQuestions: CMS.Class.QuestionDraftPayload[] = []
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    questionScenarioDrafts: CMS.Class.QuestionScenarioDraftJSON[] = []
    validationMessages: string[] = []
    isLoading = true
    manifestETag: string | null = null
    examETag: string | null = null
    fatalError = false // disables export if there's an error that would break our apps

    exportProgressMessage = 'Starting export'
    exportProgressPercent = 0
    exporting = false

    get numActiveJobs () {
        return this.activeQuestions
            .filter((q): q is CMS.Class.QuestionDraftJSON => !!(q.job))
            .map(q => q.job && q.job.objectId)
            .filter((current, index, self) => self.indexOf(current) === index).length
    }

    // Increment the version based on the type (Major, Minor, Patch)
    get newVersion (): string {
        if (this.examDraft) {
            const type = this.versionInfo.type
            const originalVersion = this.examDraft.compositeKey.split('/')[1]

            // If this is the first version of the exam, don't increment any version part
            if (!this.examDraft.examMetadataId) {
                return originalVersion
            }

            const versionParts = originalVersion.split('.')
            if (type === 'Major') {
                versionParts[0] = String(Number(versionParts[0]) + 1)
                versionParts[1] = '0'
                versionParts[2] = '0'
            } else if (type === 'Minor') {
                versionParts[1] = String(Number(versionParts[1]) + 1)
                versionParts[2] = '0'
            } else if (type === 'Patch') {
                versionParts[2] = String(Number(versionParts[2]) + 1)
            } else {
                throw new Error(`Unknown Upgrade Type: ${type}`)
            }

            return versionParts.join('.')
        }

        return ''
    }

    /**
     * Formats a date in YYYY-MM-DD HH:MM:SS format, since that is the existing format on objects in S3
     * @param {string | Date} originalDate - The date to be formatted
     */
    formatDate (originalDate: string | Date) {
        let dateObj: Date
        // If the date is a string in YYYY-MM-DD HH:MM:SS format, we want to treat it as a UTC time
        if (typeof originalDate === 'string' && originalDate.match(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/)) {
            const dateComponents = originalDate.split(' ')[0].split('-')
            const timeComponents = originalDate.split(' ')[1].split(':')
            const [ year, month, date ] = dateComponents
            const [ hours, minutes, seconds ] = timeComponents
            dateObj = new Date(Date.UTC(
                Number(year), Number(month) - 1, Number(date), Number(hours), Number(minutes), Number(seconds)
            ))
        } else {
            dateObj = new Date(originalDate)
            if (isNaN(Number(dateObj))) {
                dateObj = new Date()
            }
        }
        return `${dateObj.toISOString().slice(0, 10)} ${dateObj.toISOString().slice(11, -5)}`
    }

    get mockExamDrafts () {
        const newActiveQuestionSerialsSet = new Set(
            this.newQuestions.filter(q => q.draftStatus === 'active').map(q => q.objectId)
        )
        return ((
            this.examDraft
            && mockExamDraftsModule.getters.getMockExamDraftsByExamDraftId(this.examDraft.objectId)
        ) || [])
            .map(med => {
                // remove new questions that are active in jobs still from the mockExamDraft question serials
                const inactiveQuestionSerials = med.questionSerials.filter(qs => !newActiveQuestionSerialsSet.has(qs))
                return {
                    ...med,
                    questionSerials: inactiveQuestionSerials,
                }
            })
    }

    get mappedMockExams (): CMS.Cloud.ExportData['mockExams'] {
        const exportableMockSerialLib = this.exportableQuestions.reduce((acc, q) => {
            // We only want unarchived questions in exportJSON.mockExams
            if (q.isMockQuestion && q.Status !== 'Archived') {
                const question = { ...q }
                delete question.isMockQuestion
                acc[q.Serial] = question
            }
            return acc
        }, {} as { [serial: string]: CMS.Cloud.ExportableQuestion })

        const mappedMockExams = this.mockExamDrafts.map(mockExamDraft => {
            const exportableQuestionsPerMockExam = mockExamDraft.questionSerials
                .filter(serial => serial in exportableMockSerialLib)
                .map(serial => exportableMockSerialLib[serial])

            return {
                name: mockExamDraft.name,
                description: mockExamDraft.description,
                durationSeconds: mockExamDraft.durationSeconds,
                enabled: mockExamDraft.enabled,
                items: exportableQuestionsPerMockExam,
                mockExamId: mockExamDraft.mockExamId || mockExamDraft.objectId,
                isNewMockExam: !mockExamDraft.mockExamId,
                subjects: Object.values(exportableQuestionsPerMockExam  // TODO: Remove this after STUDY-646
                    .reduce((acc, question) => {
                        if (!(question['Knowledge Area'] in acc)) {
                            acc[question['Knowledge Area']] = {
                                name: question['Knowledge Area'],
                                count: 0,
                            }
                        }

                        acc[question['Knowledge Area']].count++

                        return acc
                    }, {} as { [ka: string]: { name: string; count: number } })),
            }
        }).filter(mockExam => mockExam.items.length)

        return mappedMockExams
    }

    get exportJSON (): CMS.Cloud.ExportData | null {
        const examDraft = this.examDraft
        if (examDraft) {
            // Archived mock questions become standard archived questions and lose their connection to the mock exam
            const standardQuestions = this.exportableQuestions.filter(q => !q.isMockQuestion || q.Status === 'Archived')

            return {
                appId: examDraft.appId,
                descriptiveName: examDraft.descriptiveName,
                nativeAppName: examDraft.nativeAppName,
                description: examDraft.description || '',
                version: this.newVersion,
                hideReferences: examDraft.hideReferences,
                isFree: examDraft.isFree || false,
                itemCount: standardQuestions.length,
                items: standardQuestions,
                mockExams: this.mappedMockExams,
                exportDate: this.formatDate(new Date()),
                columnNames: [  // It's possible that we don't actually use these column names
                    'Serial',
                    'Type',
                    'Status',
                    'Classic',
                    'Is Special',
                    'App Name',
                    'Knowledge Area',
                    'Sub Category',
                    'Question',
                    'Answer',
                    'Explanation',
                    'Passage',
                    'Reference',
                    'Distractor 1',
                    'Distractor 2',
                    'Distractor 3',
                    'Distractor 4',
                    'Distractor 5',
                    'Distractor 6',
                    'Archived',
                    'Date Added',
                    'Last Updated',
                ],
            }
        }

        return null
    }

    get newCompositeKey () {
        return this.examDraft
            ? `${this.examDraft.compositeKey.split('/')[0]}/${this.newVersion}`
            : null
    }

    get fullExportS3Payload (): Parameters<CMS.Cloud.exportExamToS3>[0] | null {
        if (this.examDraft && this.exportJSON && this.newCompositeKey) {
            return {
                compositeKey: this.newCompositeKey,
                oldCompositeKey: this.examDraft.compositeKey,
                manifest: {
                    name: this.examDraft.releaseInfo.name,
                    description: this.versionInfo.description,
                    version: this.newVersion,
                    message: this.examDraft.releaseInfo.message,
                },
                exportJSON: this.exportJSON,
            }
        }

        return null
    }

    get fullImagesExportPayload (): Parameters<CMS.Cloud.exportImages>[0] | null {
        if (this.examDraft && this.exportableImageUrls) {
            return {
                compositeKey: `${this.examDraft.compositeKey.split('/')[0]}/${this.newVersion}`,
                oldCompositeKey: this.examDraft.compositeKey,
                imageUrls: this.exportableImageUrls,
            }
        }

        return null
    }

    get questionCountsByKA () {
        return this.unarchivedQuestions.reduce((acc, q) => {
            const newAcc = acc
            if (!(q.knowledgeAreaDraft in acc)) {
                newAcc[q.knowledgeAreaDraft] = 0
            }

            if (q.isMockQuestion === 'No') {
                newAcc[q.knowledgeAreaDraft]++
            }

            return newAcc
        }, {} as { [key: string]: number })
    }

    async submitExport () {
        if (this.exporting) {
            throw new Error('ExamDraftExport: submitExport called while already exporting')
        }

        this.exporting = true

        if (this.fullExportS3Payload && this.fullImagesExportPayload && this.examDraft) {
            const oldCompositeKey = this.examDraft.compositeKey
            const compositeKey = this.newCompositeKey

            if (!compositeKey) {
                this.exporting = false
                this.validationMessages.push('error/New composite key cannot be generated')
                throw new Error('New composite key cannot be generated.')
            }

            // check that new version doesn't already exist
            this.exportProgressMessage = 'Checking for version conflicts'
            this.exportProgressPercent = 5
            const versionExists = await examsModule.actions.checkCompositeKeyExists(compositeKey)
            if (versionExists) {
                this.exporting = false
                this.validationMessages.push('error/Cannot export that version. Version already exists.')
                throw new Error(`Error exporting ${compositeKey}. Version already exists.`)
            }

            // export images to s3 and move previous version's images to new folder in s3
            this.exportProgressMessage = 'Copying images to S3 bucket'
            this.exportProgressPercent = 10
            try {
                await examsModule.actions.exportImages(this.fullImagesExportPayload)
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to copy images to S3 bucket.')
                throw e
            }

            // send json files to s3
            this.exportProgressMessage = 'Uploading manifest and export files to S3'
            this.exportProgressPercent = 30
            let manifestETag = '',
                examETag = ''
            try {
                ({
                    manifestETag,
                    examETag,
                } = await examsModule.actions.exportExamToS3(this.fullExportS3Payload))
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to copy JSON files to S3 bucket.')
                throw e
            }

            // mark all parse data dirty
            this.exportProgressMessage = 'Marking live data dirty'
            this.exportProgressPercent = 40
            try {
                await examsModule.actions.updateOldParseData({
                    ...this.fullExportS3Payload,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to send data to App server directly.')
                throw e
            }

            // send all data to parse directly
            this.exportProgressMessage = 'Updating database'
            this.exportProgressPercent = 50
            let newExamMetadataId = ''
            try {
                newExamMetadataId = await examsModule.actions.exportExamToParse({
                    ...this.fullExportS3Payload,
                    manifestETag,
                    examETag,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to send data to App server directly.')
                throw e
            }

            // send questions and subjects to parse directly
            this.exportProgressMessage = 'Exporting questions and subjects'
            this.exportProgressPercent = 65
            const liveQuestionSerialSet = new Set(this.allQuestionDraftJSON.map(q => q.serial))
            const scenariosWithLiveQuestions = this.questionScenarioDrafts.reduce((acc, scenarioDraft) => {
                const scenarioWithLiveQuestions = {
                    ...scenarioDraft,
                    questionDrafts: scenarioDraft.questionDrafts.filter(qd => liveQuestionSerialSet.has(qd.serial)),
                }
                if (scenarioWithLiveQuestions.questionDrafts.length) {
                    acc.push(scenarioWithLiveQuestions)
                }
                return acc
            }, [] as CMS.Class.QuestionScenarioDraftJSON[])
            try {
                await examsModule.actions.exportQuestionsToParse({
                    compositeKey,
                    examMetadataId: newExamMetadataId,
                    questions: this.allQuestionDraftJSON,
                    subjects: this.knowledgeAreaDrafts,
                    questionScenarios: scenariosWithLiveQuestions,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to send questions or subjects to App server directly.')
                throw e
            }

            // update redis questions
            this.exportProgressMessage = 'Updating Redis with new questions'
            this.exportProgressPercent = 70
            try {
                await examsModule.actions.updateRedisQuestions(compositeKey)
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to update questions in Redis.')
                throw e
            }

            // update all bundles if major release
            if (oldCompositeKey.split('/')[1].split('.')[0] !== this.newVersion.split('.')[0]) {
                this.exportProgressMessage = 'Updating bundles'
                this.exportProgressPercent = 75
                try {
                    await examsModule.actions.updateBundlesForExamMajor({
                        examGuid: oldCompositeKey.split('/')[0].toUpperCase(),
                        newExamId: newExamMetadataId,
                    })
                } catch (e) {
                    this.exporting = false
                    this.validationMessages.push('error/Unable to update bundles.')
                    throw e
                }
            }

            // delete old data in Parse
            this.exportProgressMessage = 'Deleting old data'
            this.exportProgressPercent = 80
            try {
                await examsModule.actions.deleteOldParseData({
                    ...this.fullExportS3Payload,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to send data to App server directly.')
                throw e
            }

            // delete data from CMS
            this.exportProgressMessage = 'Cleaning up CMS data'
            this.exportProgressPercent = 90
            try {
                await examsModule.actions.cleanUpExport({
                    oldCompositeKey,
                    compositeKey,
                })
                // Remove the current exam draft from the store state
                examDraftsModule.state.examDrafts = examDraftsModule.state.examDrafts.filter(
                    examDraft => !this.examDraft || examDraft.objectId !== this.examDraft.objectId
                )
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to delete old CMS data.')
                throw e
            }

            // store questions with reset metrics and clear global metrics
            if (this.resetQuestions.length) {
                this.exportProgressMessage = 'Storing Questions w/ Quality Reset'
                this.exportProgressPercent = 95
                try {
                    await examsModule.actions.questionQualityResetV2({
                        questions: this.resetQuestions,
                    })
                } catch (e) {
                    this.validationMessages.push('warning/Unable to store and reset Question Quality metrics.')
                }
            }

            await examsModule.actions.fetchExams()

            // log activity
            this.exportProgressMessage = 'Export complete!'
            this.exportProgressPercent = 100
            const safeKnowledgeAreas = Object.entries(this.questionCountsByKA).reduce((acc, [ ka, count ]) => {
                acc[ka.replace(/\./g, '_').replace(/\$/g, '')] = count
                return acc
            }, {} as { [key: string]: number })
            await activitiesModule.actions.createActivity({
                action: 'export',
                subject: {
                    type: 'Directory',
                    value: oldCompositeKey.split('/')[0].toUpperCase(),
                    name: compositeKey,
                },
                type: 'exam',
                data: {
                    questionCount: this.unarchivedQuestions.length,
                    archivedCount: this.archivedQuestions.length,
                    knowledgeAreas: safeKnowledgeAreas,
                    specialCount: this.numFree,
                },
            })

            // redirect to exam view page
            this.$router.push({
                name: 'exam-view',
                params: {
                    examId: newExamMetadataId,
                },
            })
        } else {
            this.validationMessages.push('error/Unknown error exporting exam.')
        }

        this.exporting = false
    }

    cancelExamExport () {
        if (this.examDraft && this.examDraft.objectId) {
            this.$router.push({
                name: 'exam-draft-edit',
                params: {
                    examDraftId: this.examDraft.objectId,
                },
            })
        }
    }

    mapQuestion (q: IQuestionDraft | CMS.Class.QuestionDraftJSON): IMappedQuestion {
        let kaObj: CMS.Class.KnowledgeAreaDraftJSON | undefined
        let kaName: string | undefined
        if ('knowledgeAreaDraftId' in q) {  // q is IQuestionDraft
            kaObj = this.knowledgeAreaDrafts.find(ka => ka.objectId === q.knowledgeAreaDraftId)
            kaName = kaObj && kaObj.name
        } else if ('knowledgeAreaDraft' in q) { // q is IParseQuestionDraft
            const kaDraftPointer = q.knowledgeAreaDraft
            kaObj = kaDraftPointer && this.knowledgeAreaDrafts.find(ka => ka.objectId === kaDraftPointer.objectId)
            kaName = kaObj && kaObj.name
        }
        const willResetMetrics = ('willResetMetrics' in q && q.willResetMetrics) ? 'Yes' : 'No'
        const subtopic = q.subtopicId && kaObj?.subtopics?.find(sub => sub.id === q.subtopicId)?.name
        return {
            objectId: q.objectId as string,
            serial: q.serial || '',
            knowledgeAreaDraft: kaName || '',
            prompt: q.prompt || '',
            type: q.type,
            draftType: 'Existing',
            isArchived: q.isArchived ? 'Yes' : 'No',
            isFree: q.isSpecial ? 'Yes' : 'No',
            appName: q.appName || '',
            subCategory: q.subCategory || '',
            answers: q.answers || [],
            explanation: q.explanation || '',
            passage: q.passage || '',
            references: q.references || [],
            distractors: q.distractors || [],
            dateAdded: q.dateAdded,
            images: q.images,
            willResetMetrics,
            isMockQuestion: q.isMockQuestion ? 'Yes' : 'No',
            subtopic: subtopic || '',
            bloomTaxonomyLevel: q.bloomTaxonomyLevel || 'None',
        }
    }

    get mappedOriginalQuestions (): Partial<IMappedQuestion>[] {
        return this.originalQuestions.map(question => {
            const kaObj = this.knowledgeAreaDrafts
                .find(ka => question.knowledgeAreaDraft
                    && 'objectId' in question.knowledgeAreaDraft
                    && ka.objectId === question.knowledgeAreaDraft.objectId
                )
            const kaName = kaObj && kaObj.name
            const subtopic = question.subtopicId && kaObj?.subtopics?.find(sub => sub.id === question.subtopicId)?.name

            return {
                objectId: question.objectId,
                knowledgeArea: kaName,
                type: question.type,
                prompt: question.prompt,
                serial: question.serial,
                explanation: question.explanation || '',
                passage: question.passage || '',
                reference: question.references ? question.references.join(' ') : '',
                isFree: question.isSpecial ? 'Yes' : 'No',
                isArchived: question.isArchived ? 'Yes' : 'No',
                percentCorrect: question.percentCorrect,
                answeredCount: (question.answeredCorrectlyCount || 0) + (question.answeredIncorrectlyCount || 0),
                explanationImage: question.images?.explanation ? 'Yes' : 'No',
                passageImage: question.images?.passage ? 'Yes' : 'No',
                isMockQuestion: question.isMockQuestion ? 'Yes' : 'No',
                subtopic: subtopic || '',
                bloomTaxonomyLevel: question.bloomTaxonomyLevel || 'None',
            }
        })
    }

    get mappedExistingQuestions (): IMappedQuestion[] {
        return this.existingQuestions.map(this.mapQuestion)
    }

    get mappedNewQuestions (): IMappedQuestion[] {
        return this.newQuestions.map<IMappedQuestion>(q => ({ ...this.mapQuestion(q), draftType: 'New' }))
    }

    get mappedUpdatedQuestions (): IMappedQuestion[] {
        return this.updatedQuestions.map(q => ({ ...this.mapQuestion(q), draftType: 'Updated' }))
    }

    get newAndUpdatedQuestions () {
        return [
            ...this.newQuestions,
            ...this.updatedQuestions,
        ]
    }

    get allQuestionDraftJSON () {
        const kaDraftLib = this.knowledgeAreaDrafts.reduce((acc, ka) => {
            acc[ka.objectId] = {
                objectId: ka.objectId,
                name: ka.name,
            }
            return acc
        }, {} as { [kaId: string]: Pick<CMS.Class.KnowledgeAreaDraftJSON, 'name' | 'objectId'> })
        return [
            ...this.existingQuestions,
            ...this.newQuestions,
            ...this.updatedQuestions,
        ].reduce((acc, q) => {
            const kaId = (q.knowledgeAreaDraft && 'id' in q.knowledgeAreaDraft)
                ? q.knowledgeAreaDraft.id
                : q.knowledgeAreaDraft?.objectId

            if (!kaId) {
                return acc
            }

            acc.push({
                ...q,
                knowledgeAreaDraft: kaDraftLib[kaId],
            } as CMS.Class.QuestionDraftJSON)

            return acc
        }, [] as CMS.Class.QuestionDraftJSON[])
    }

    get mappedQuestions () {
        return [
            ...this.mappedNewQuestions,
            ...this.mappedUpdatedQuestions,
            ...this.mappedExistingQuestions,
        ]
    }

    get unarchivedQuestions () {
        return this.mappedQuestions.filter(q => q.isArchived === 'No' && q.isMockQuestion === 'No')
    }

    get unarchivedMockQuestions () {
        return this.mappedQuestions.filter(q => q.isArchived === 'No' && q.isMockQuestion === 'Yes')
    }

    get archivedQuestions () {
        return this.mappedQuestions.filter(q => q.isArchived === 'Yes')
    }

    get numFree () {
        return this.unarchivedQuestions.filter(q => q.isFree === 'Yes').length
    }

    get exportableImageUrls (): string[] {
        return this.mappedQuestions.reduce((acc, cur) => {
            const updatedAcc = acc

            if (cur.images) {
                if (cur.images.explanation) {
                    updatedAcc.push(cur.images.explanation.url)
                }
                if (cur.images.passage) {
                    updatedAcc.push(cur.images.passage.url)
                }
            }

            return updatedAcc
        }, [] as string[])
    }

    get exportableQuestions (): (CMS.Cloud.ExportableQuestion & { isMockQuestion?: boolean })[] {
        return this.mappedQuestions.map((q): CMS.Cloud.ExportableQuestion & { isMockQuestion?: boolean } => {

            const exportableQuestion: CMS.Cloud.ExportableQuestion & { isMockQuestion: boolean } = {
                'Serial': q.serial,
                'Type': q.type,
                'Status': q.isArchived === 'Yes' ? 'Archived' as const : 'Complete' as const,
                'Classic': 0,   // Legacy property, no longer used
                'Is Special': q.isFree === 'Yes' ? 1 : 0,
                'App Name': q.appName,
                'Knowledge Area': q.knowledgeAreaDraft,
                'Sub Category': q.subCategory,
                'Question': q.prompt,
                'Answer': q.answers[0],
                'Answer 2': q.answers[1],
                'Answer 3': q.answers[2],
                'Answer 4': q.answers[3],
                'Answer 5': q.answers[4],
                'Answer 6': q.answers[5],
                'Answer 7': q.answers[6],
                'Answer 8': q.answers[7],
                'Answer 9': q.answers[8],
                'Explanation': q.explanation,
                'Passage': q.passage,
                'Reference': q.references.join(''),
                'Distractor 1': q.distractors[0],
                'Distractor 2': q.distractors[1],
                'Distractor 3': q.distractors[2],
                'Distractor 4': q.distractors[3],
                'Distractor 5': q.distractors[4],
                'Distractor 6': q.distractors[5],
                'Distractor 7': q.distractors[6],
                'Distractor 8': q.distractors[7],
                'Distractor 9': q.distractors[8],
                'Archived': q.isArchived === 'Yes' ? 1 : 0,
                'Date Added': q.dateAdded ? this.formatDate(q.dateAdded) : this.formatDate(new Date()),
                'Last Updated': this.formatDate(new Date()),
                isMockQuestion: q.isMockQuestion === 'Yes' ? true : false,
            }

            if (q.images && (q.images.explanation || q.images.passage)) {
                exportableQuestion.images = {}
                if (q.images.explanation) {
                    exportableQuestion.images.Explanation = this.imageUrlRegex(q.images.explanation.url)
                    exportableQuestion.images.explanation = {
                        url: this.imageUrlRegex(q.images.explanation.url),
                        altText: q.images.explanation.altText,
                        longAltText: q.images.explanation.longAltText,
                    }
                }
                if (q.images.passage) {
                    exportableQuestion.images.Question = this.imageUrlRegex(q.images.passage.url)
                    exportableQuestion.images.passage = {
                        url: this.imageUrlRegex(q.images.passage.url),
                        altText: q.images.passage.altText,
                        longAltText: q.images.passage.longAltText,
                    }
                }
            }

            return exportableQuestion
        })
    }

    get exportQuestionListOptions (): IListOptions<IMappedQuestion> {
        return {
            listData: this.mappedQuestions,
            listSchema: [
                {
                    propName: 'knowledgeAreaDraft',
                    label: 'Subject',
                    type: 'text',
                    options: {
                        width: 250,
                        group: 0,
                    },
                    data: this.sortedKnowledgeAreas.map(ka => ka.name),
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    data: this.sortedKnowledgeAreas?.flatMap(ka => ka.subtopics?.map(sub => sub.name) || []),
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s Taxonomy Level',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'type',
                    label: 'Type',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                    data: [ 'Multiple Choice', 'True/False' ],
                },
                {
                    propName: 'draftType',
                    label: 'Draft Type',
                    type: 'text',
                    data: [ 'New', 'Updated', 'Existing' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isFree',
                    label: 'Free',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isArchived',
                    label: 'Archived',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        filter: 'No',
                    },
                },
                {
                    propName: 'prompt',
                    label: 'Prompt',
                    type: 'text',
                    options: {
                        style: 'overflow-ellipsis',
                        group: 1,
                        minWidth: 250,
                    },
                },
                {
                    propName: 'willResetMetrics',
                    label: 'Metrics Will Reset',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock Question',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
            ],
            defaultSort: {
                propName: 'draftType',
                sortDir: 'DESC',
            },
            listDataModifiers: [
                data => data.isArchived === 'Yes' && { opacity: '0.3' },
                data => data.draftType === 'Updated' && { backgroundColor: 'rgba(255, 150, 0, 0.3)' },
                data => data.draftType === 'New' && { backgroundColor: 'rgba(100, 255, 100, 0.2)' },
            ],
            listDataIcons: [
                data => data.isFree === 'Yes' && {
                    iconName: 'gift',
                    label: 'Special',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.willResetMetrics === 'Yes' && {
                    iconName: 'broom',
                    label: 'Reset Metrics',
                    styles: {
                        color: 'rgb(255, 0, 0)',
                        backgroundColor: '#fff',
                        fontSize: '15px',
                    },
                },
            ],
        }
    }

    get sortedKnowledgeAreas (): CMS.Class.KnowledgeAreaDraftJSON[] {
        // We don't show archived KAs in ExamView, so would be confusing to show them here
        return this.knowledgeAreaDrafts.filter(kaDraft => !kaDraft.isArchived).sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true })
        )
    }

    // remove beginning part of image url
    imageUrlRegex (imageUrl: string) {
        const createRegExp = (str: TemplateStringsArray) => 
            new RegExp(str.raw[0].replace(/\s/gm, ''), '')

        return imageUrl.replace(createRegExp`https:\/\/s3\.amazonaws\.com\/
            (?:[^\/])+\/(?:[^\/])+\/[0-9.]+\/`, '')
    }

    processExamDraftQuestion (examDraftQuestion: CMS.Class.QuestionDraftJSON) {
        if (examDraftQuestion.hasComments || examDraftQuestion.hasSuggestions) {
            const mappedExamDraftQuestion = {
                ...examDraftQuestion,
                prompt: stripCKEditorTags(examDraftQuestion.prompt || ''),
                passage: stripCKEditorTags(examDraftQuestion.passage || ''),
                answers: examDraftQuestion.answers && examDraftQuestion.answers.map(stripCKEditorTags),
                distractors: examDraftQuestion.distractors && examDraftQuestion.distractors.map(stripCKEditorTags),
                references: examDraftQuestion.references && examDraftQuestion.references.map(stripCKEditorTags),
                explanation: stripCKEditorTags(examDraftQuestion.explanation || ''),
            }
            return mappedExamDraftQuestion
        }

        return examDraftQuestion
    }

    async fetchOrGetExamDraft (examDraftId: string) {
        return examDraftsModule.getters.getExamDraft(examDraftId)
            || await examDraftsModule.actions.fetchExamDraft(examDraftId)
    }

    async mounted () {
        this.isLoading = true

        try {
            this.s3BucketName = await examsModule.actions.fetchS3BucketName()

            const examDraftId = typeof this.$route.params.examDraftId === 'string'
                ? this.$route.params.examDraftId
                : this.$route.params.examDraftId[0]

            this.examDraft = (await this.fetchOrGetExamDraft(examDraftId)) || null

            // Fetch all the question drafts for the current exam
            const examDraftQuestions = (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    examDraft: { __type: 'Pointer', className: 'ExamDraft', objectId: examDraftId },
                },
            })).results

            this.activeQuestions = examDraftQuestions.filter(q => q.draftStatus === 'active')
            const inactiveQuestions = examDraftQuestions.filter(q => q.draftStatus !== 'active')
                .map(this.processExamDraftQuestion)

            this.newQuestions = inactiveQuestions.filter(q => !q.examDataId)
            this.updatedQuestions = inactiveQuestions.filter(q => q.examDataId)

            // Fetch the exam's old questions, in IQuestionDraft form
            const originalParseQuestions = this.examDraft
                && this.examDraft.examMetadataId
                && await questionsModule.actions.fetchQuestionsByExam({ examMetadataId: this.examDraft.examMetadataId })

            // package up any questions that will have their metrics reset on export
            const resetSerials = this.updatedQuestions.reduce((accum, question) =>
                (question.willResetMetrics && question.serial)
                    ? accum.add(question.serial)
                    : accum
            , new Set() as Set<string>)
            if (originalParseQuestions) {
                this.resetQuestions = originalParseQuestions.filter(q => resetSerials.has(q.serial))
                    .map(q => ({
                        ...q,
                        subject: objPointer(q.subject.objectId)('Subject'),
                    }))
            }

            // Fetch knowledge areas
            this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(examDraftId)

            // Fetch question scenario drafts
            this.questionScenarioDrafts = await questionScenarioDraftsModule.actions
                .fetchQuestionScenarioDraftsByExamDraftId(examDraftId)

            // check that there are knowledge area drafts for all original questions
            const missingKnowledgeAreas = originalParseQuestions && originalParseQuestions.filter(item =>
                !this.knowledgeAreaDrafts.find(ka => ka.name === (item.subject as Study.Class.SubjectJSON).name))

            // if missing knowledge areas, create them and refetch all
            if (missingKnowledgeAreas && missingKnowledgeAreas.length) {
                const kaPromises = R.uniqBy(q => (q.subject as Study.Class.SubjectJSON).name, missingKnowledgeAreas)
                    .map(ka => kaDraftsModule.actions.createKADraft({
                        name: (ka.subject as Study.Class.SubjectJSON).name,
                        examDraftId: (this.examDraft && this.examDraft.objectId) || '',
                        isArchived: true,
                        subjectId: (ka.subject as Study.Class.SubjectJSON).objectId,
                    }))
                await Promise.all(kaPromises)
                this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(examDraftId)
            }

            this.originalQuestions = originalParseQuestions
                && await Promise.all(originalParseQuestions.map(async item => {
                    const knowledgeAreaDraft = this.knowledgeAreaDrafts
                        .find(ka => ka.name === (item.subject as Study.Class.SubjectJSON).name)

                    return await questionDraftsModule.actions.convertPQToPQDraft({
                        question: item,
                        examDraft: this.examDraft || undefined,
                        knowledgeAreaDraft,
                    })
                })
                )
                || []

            // An existing question is a question with no corresponding inactive question draft
            this.existingQuestions = this.originalQuestions.filter(question => !inactiveQuestions.find(
                qDraft => !!qDraft.examDataId && qDraft.serial === question.serial
            ))

            // fetch mock exam drafts
            this.examDraft && await mockExamDraftsModule.actions.fetchMockExamDrafts({
                examDraftId: this.examDraft.objectId,
            })

            // warn if exam has 0 free questions
            if (this.numFree === 0) {
                this.fatalError = true
                this.validationMessages.push('error/Error: This exam has 0 free questions.')
            }

            // warn if any of the questions are missing serial
            const questionSerialIssues = this.mappedQuestions.filter(q => !q.serial)
            if (questionSerialIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (objectIDs) are missing 
                serial number: ${questionSerialIssues.map(q => q.objectId)}`)
            }

            // warn if any of the questions is missing KA
            const questionKaIssues = this.mappedQuestions.filter(q => !q.knowledgeAreaDraft)
            if (questionKaIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (serials) are missing
                knowledge area/subject: ${questionKaIssues.map(q => q.serial)}`)
            }

            // warn if any of the questions is missing explanation
            const questionExplanationIssues = this.mappedQuestions.filter(q => !q.explanation)
            if (questionExplanationIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (serials) are missing 
                explanation: ${questionExplanationIssues.map(q => q.serial)}`)
            }

            // warn if we only have question drafts for part of a scenario
            const partialScenarios = this.checkForPartialScenarios()
            if (partialScenarios.length) {
                partialScenarios.forEach(partialScenario => {
                    this.validationMessages.push(`warning/Warning: Scenario ${partialScenario.key}
                    has ${partialScenario.totalCount} questions, but only ${partialScenario.updatedCount}
                    will be updated in this export.`)
                })
            }

            // warn if we're updating a serial that also exists in other exams
            const sharedSerials = await this.checkForSharedSerials()
            if (sharedSerials.length) {
                sharedSerials.forEach(sharedSerial => {
                    this.validationMessages.push(`warning/Warning: Question serial ${sharedSerial.serial} will
                    be updated in this export. There are ${sharedSerial.examNames.length} other questions in
                    different exams with that serial: ${sharedSerial.examNames.join(', ')}.`)
                })
            }

            // warn if we have any question drafts with invalid scenario draft pointers
            const qDraftIdsWithInvalidScenario = this.checkForQDraftsWithInvalidScenario()
            if (qDraftIdsWithInvalidScenario.length) {
                this.fatalError = true
                const errorMessage = `The following question drafts have invalid scenario pointers:
                ${qDraftIdsWithInvalidScenario.join(', ')}`
                Sentry.captureException(new Error(errorMessage))
                this.validationMessages.push(`error/Error: ${errorMessage}. Contact dev to repair scenario data`)
            }

            // warn if we have any scenario drafts with invalid question draft serials
            const scenarioIdsWithInvalidSerials = this.checkForScenariosWithInvalidSerials()
            if (scenarioIdsWithInvalidSerials.length) {
                this.fatalError = true
                const errorMessage = `The following scenarios have invalid serial references:
                ${scenarioIdsWithInvalidSerials.join(', ')}`
                Sentry.captureException(new Error(errorMessage))
                this.validationMessages.push(`error/Error: ${errorMessage}. Contact dev to repair scenario data`)
            }

            // Warn if trying to archive questions outside of a major export
            const oldArchivedCount = this.mappedOriginalQuestions.filter(q => q.isArchived === 'Yes').length
            if (this.archivedQuestions.length > oldArchivedCount) {
                const newlyArchivedCount = this.archivedQuestions.length - oldArchivedCount
                this.validationMessages.push(
                    `warning/Warning: ${ newlyArchivedCount } newly archived question${
                        newlyArchivedCount === 1 ? '' : 's'
                    } - consider using a Major export`
                )
            }

            // Show a warning if the exam draft is not the most recent version of the exam
            if (this.examDraft && this.examDraft.examMetadataId) {
                const currentExamVersion = this.examDraft.compositeKey.split('/')[1]
                const mostRecentExamVersion = await examsModule.actions.fetchMostRecentExamVersion(
                    this.examDraft.compositeKey
                )
                if (mostRecentExamVersion && currentExamVersion !== mostRecentExamVersion) {
                    this.validationMessages
                        .push(
                            'warning/Warning: ' +
                            `${this.examDraft.nativeAppName} has a newer version (${mostRecentExamVersion})`
                        )
                }
            }

            // warn if any unarchived knowledge areas are empty
            const kaWarnings = this.knowledgeAreaDrafts
                .filter(ka => !this.questionCountsByKA[ka.name] && !ka.isArchived)
                .map(ka => `warning/Warning: Knowledge area, ${ka.name}, has 0 questions.`)
            if (kaWarnings.length) {
                this.validationMessages = [
                    ...this.validationMessages,
                    ...kaWarnings,
                ]
            }

            // warn if any images are missing alt text
            const altTextIssues = this.mappedQuestions.filter(q => 
                !q.isArchived && (
                    (q.images?.explanation?.url && !q.images.explanation.altText)
                    || (q.images?.passage?.url && !q.images.passage.altText)
                )
            )
            if (altTextIssues.length) {
                this.validationMessages.push(`warning/Warning: There ${altTextIssues.length > 1 ? 'are' : 'is'}` +
                ` ${altTextIssues.length} question${altTextIssues.length > 1 ? 's' : ''} with missing alt text.` +
                ` Serial(s): ${altTextIssues.map(q => q.serial).join(', ')}`)
            }

            // warn if # of active questions decreases
            const exam = examsModule.state.exams
                .find(e => this.examDraft && e.compositeKey === this.examDraft.compositeKey)
            const questionsFewer = exam ? this.unarchivedQuestions.length - (exam.itemCount - exam.archivedCount) : 0
            if (questionsFewer < 0) {
                this.validationMessages.push('error/Warning: The exam you are about to export has ' +
                    `${Math.abs(questionsFewer)} fewer questions than the previous version of the exam.`)
            }

            // warn if there are duplicate serials
            const duplicateMessages = R.pipe(
                (qs: { serial: string }[]) => qs.filter((q) => !!q.serial),
                R.groupBy(R.prop('serial')),
                R.mapObjIndexed(R.prop('length')),
                s => Object.entries(s)
                    .filter(([ , count ]) => count > 1)
                    .map(([ serial ]) => `error/Duplicate serial: ${serial}`)
            )(inactiveQuestions as { serial: string }[])

            if (duplicateMessages.length) {
                this.fatalError = true
                this.validationMessages = [
                    ...this.validationMessages,
                    ...duplicateMessages,
                ]
            }

            // warn if any NEW questions have serials that conflict with live questions
            const liveSerials = (typeof originalParseQuestions === 'object') && originalParseQuestions
                ? originalParseQuestions.map(q => q.serial)
                : []
            const serialConflicts = this.newQuestions.reduce((acc, q) => {
                if (!q.examDataId && q.serial && liveSerials.includes(q.serial)) {
                    acc.push(q.serial)
                }
                return acc
            }, [] as string[])
            if (serialConflicts.length) {
                const serialConflictMessages = serialConflicts
                    .map(serial => `error/New question serial conflict: ${serial}`)
                this.fatalError = true
                this.validationMessages = [
                    ...this.validationMessages,
                    ...serialConflictMessages,
                ]
            }
        } catch (err) {
            this.validationMessages.push('error/Unable to load export data.')
        }

        // warn if there are questions still in active jobs
        if (this.activeQuestions.length > 0) {
            this.validationMessages.push(
                `error/Warning: This exam still has ${this.activeQuestions.length} ${this.activeQuestions.length === 1
                    ? 'question' : 'questions'} in ${this.numActiveJobs} active ${this.numActiveJobs === 1
                    ? 'job' : 'jobs'}!`
            )
        }

        this.isLoading = false
    }

    checkForPartialScenarios (): { key: string; totalCount: number; updatedCount: number }[] {
        const partialScenarios: {key: string; totalCount: number; updatedCount: number }[] = []
        const exportSerialSet = new Set(this.newAndUpdatedQuestions.map(q => q.serial as string))
        this.questionScenarioDrafts.forEach(scenarioDraft => {
            const scenarioSerials = scenarioDraft.questionDrafts.map(qd => qd.serial)
            const filteredScenarioSerials = scenarioSerials.filter(serial => exportSerialSet.has(serial))
            // compare the scenario's serials with our new/updated question draft serials
            // we should either be updating 0 or all of a scenario's questions at one time
            if (filteredScenarioSerials.length !== 0 && filteredScenarioSerials.length !== scenarioSerials.length) {
                partialScenarios.push({
                    key: scenarioDraft.key,
                    totalCount: scenarioSerials.length,
                    updatedCount: filteredScenarioSerials.length,
                })
            }
        })
        return partialScenarios
    }

    async checkForSharedSerials (): Promise<{ serial: string; examNames: string[] }[]> {
        // TODO - will use a new cloud function here to query Question records for shared serials

        // For each question draft's serial,
        // - check for Question records in other exams with the same serial
        // - if found, return the serial with the names of those other exams

        return []
    }

    checkForQDraftsWithInvalidScenario (): string[] {
        const qDraftIdsWithInvalidScenario: string[] = []
        
        const fetchedScenarioDraftSet = new Set(this.questionScenarioDrafts.map(scenario => scenario.objectId))
        // For each question draft with a scenario pointer
        this.newAndUpdatedQuestions.forEach(q => {
            // check that the pointer matches a real scenario draft
            if (q.questionScenarioDraft && !fetchedScenarioDraftSet.has(q.questionScenarioDraft.objectId)) {
                qDraftIdsWithInvalidScenario.push(q.serial || q.objectId)
            }
        })

        return qDraftIdsWithInvalidScenario
    }

    checkForScenariosWithInvalidSerials (): string[] {
        const scenarioIdsWithInvalidSerials: string[] = []
        const activeSerialSet = new Set(this.activeQuestions.map(q => q.serial as string))
        const exportSerialSet = new Set(this.allQuestionDraftJSON.map(q => q.serial as string))
        // For each scenario draft
        this.questionScenarioDrafts.forEach(scenarioDraft => {
            // check that all of the scenario's serials are going to be exported
            const missingQuestionDraft = scenarioDraft.questionDrafts.find(qd =>
                !exportSerialSet.has(qd.serial) && !activeSerialSet.has(qd.serial)
            )
            if (missingQuestionDraft) {
                scenarioIdsWithInvalidSerials.push(scenarioDraft.objectId)
            }
        })
        return scenarioIdsWithInvalidSerials
    }
}
